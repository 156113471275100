// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coffee-js": () => import("./../../../src/pages/coffee.js" /* webpackChunkName: "component---src-pages-coffee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-hci-assignment-1-js": () => import("./../../../src/pages/projects/hci/assignment1.js" /* webpackChunkName: "component---src-pages-projects-hci-assignment-1-js" */),
  "component---src-pages-projects-hci-index-js": () => import("./../../../src/pages/projects/hci/index.js" /* webpackChunkName: "component---src-pages-projects-hci-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

